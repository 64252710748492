import { configureStore } from "@reduxjs/toolkit";
import alertReducer from './alertSlice';
import applicationUserSlice from "./applicationUserSlice";

export const store = configureStore({
    reducer: {
        alert: alertReducer,
        applicationUser: applicationUserSlice,
    }
});
