import { useAuth } from "oidc-react";
import { ReactElement, Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ApplicationUserRole } from "../../api";
import useCurrentUserRoles from "../../hooks/useCurrentUserRoles";
import { getCurrentUser } from "../../redux/applicationUserSlice";
import { loadingSpin } from "./routingContainer";

export function AuthInitialized(props: { children: ReactElement, loadingIndicator: ReactElement }) {
    const [ isTryingToLogin, setIsTryingToLogin ] = useState<boolean>(false);
    const auth = useAuth();

    useEffect(() => {
        if (!auth.isLoading && !auth.userData) {
            setIsTryingToLogin(true);

            auth.userManager.signinSilent().finally(() => setIsTryingToLogin(false));
        }
    }, [auth.isLoading, auth.userData, auth.userManager]);

    if (auth.isLoading) {
        return props.loadingIndicator || null;
    }

    if (isTryingToLogin) {
        return props.loadingIndicator || null;
    }

    return <Suspense fallback={loadingSpin}>
        {props.children}
    </Suspense>;
}

export function Protected(props: { children: ReactElement, loadingIndicator: ReactElement, allowedForRoles?: ApplicationUserRole[] }) {
    const auth = useAuth();
    const navigate = useNavigate();
    const user = useSelector(getCurrentUser);
    const roles = useCurrentUserRoles();
    const location = useLocation();

    useEffect(() => {
        if (props.allowedForRoles && user && !roles.some(r => props.allowedForRoles!.includes(r))) {
            navigate("/", { replace: true });
        }
    }, [user, roles, props.allowedForRoles, navigate]);

    if (auth.isLoading) {
        return props.loadingIndicator;
    }

    if (!auth.userData) {
        auth.userManager.signinSilent().catch(() => auth.signIn({ state: { targetPath: location.pathname } }));

        return props.loadingIndicator;
    }

    return <Suspense fallback={loadingSpin}>
        {props.children}
    </Suspense>;
}
