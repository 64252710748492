import { LoginOutlined, LogoutOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { Header } from "antd/lib/layout/layout";
import { User } from "oidc-react";
import React from 'react';
import styles from './headerComponent.module.less';
import { AsyncButton } from "digimuth-components/dist";

interface HeaderComponentProps {
    onLogoutPressed: () => Promise<any>,
    onLoginPressed: () => Promise<any>,
    userData?: User | null;
}

export default function HeaderComponent(props: HeaderComponentProps) {
    if (props.userData) {
        return <Header className={styles.exHeader}>
            <Row justify='end'>
                <Col xs={0} sm={20} className={styles.userNameWrapper}>
                    <span>{props.userData.profile.name}</span>
                </Col>
                <Col xs={2}>
                    <AsyncButton
                        type="text"
                        onClick={props.onLogoutPressed}
                        icon={<LogoutOutlined />}
                    >
                        Wyloguj
                    </AsyncButton>
                </Col>
            </Row>
        </Header>;
    }

    return <Header className={styles.exHeader}>
        <Row>
            <div style={{flex: 1}} />
            <Col>
                <AsyncButton type="text" onClick={props.onLoginPressed} icon={<LoginOutlined />}>Zaloguj</AsyncButton>
            </Col>
        </Row>
    </Header>;
}
