import React from "react";
import { Route, Routes } from "react-router-dom";
import { RoutingDefinition } from "digimuth-components/dist/components/crudBreadcrumb/crudBreadcrumb";
import styles from "./routingContainer.module.less";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import * as Pages from "./pages";

const mainPage = { route: "/", name: "Strona główna" };
const users = { route: "/users", name: "Lista użytkowników" }
const reportingUsers = { route: "/reporting-users", name: "Lista urządzeń" }
const transactions = { route: "/transactions", name: "Lista transakcji" }

export const RoutingPaths: { [key: string]: RoutingDefinition } = {
    login: { route: "/login" },
    activate: { route: "/activate" },
    forgotPassword: { route: "/forgotPassword" },
    users: { route: "/users", breadcrumbEntries: [ mainPage, users ] },

    reportingUsers: {
        route: "/reporting-users",
        breadcrumbEntries: [ mainPage, reportingUsers ]
    },
    
    reportingUserDetails: {
        route: "/reporting-users/:id",
        breadcrumbEntries: [ mainPage, reportingUsers, { name: "Szczegóły urządzenia" } ]
    },

    transactions: {
        route: transactions.route,
        breadcrumbEntries: [ mainPage, transactions ] },

    reportingUserTransactions: {
        route: transactions.route + "/:id",
        breadcrumbEntries: [mainPage, transactions] },

    transactionDetails: {
        route: "/transaction/:id",
        breadcrumbEntries: [ mainPage, transactions, { name: "Szczegóły transakcji" } ]
    }
};

const loadingIcon = <LoadingOutlined className={styles.loadingIcon} spin />;
export const loadingSpin = <Spin className={styles.loadingContainer} indicator={loadingIcon} />

export default function RoutingContainer() {
    return <Routes>
        <Route path={RoutingPaths.login.route} element={<Pages.LoginPage />} />
        <Route path={RoutingPaths.activate.route} element={<Pages.ActivatePage />} />
        <Route path={RoutingPaths.forgotPassword.route} element={<Pages.ForgotPasswordPage />} />
        <Route path={RoutingPaths.users.route} element={<Pages.UsersPage />} />

        <Route path={RoutingPaths.reportingUsers.route} element={<Pages.ReportingUsersPage />} />
        <Route path={RoutingPaths.reportingUserDetails.route} element={<Pages.ReportingUserDetailsPage />} />

        <Route path={RoutingPaths.transactions.route} element={<Pages.TransactionsPage />} />
        <Route path={RoutingPaths.reportingUserTransactions.route} element={<Pages.TransactionsPage />} />
        <Route path={RoutingPaths.transactionDetails.route} element={<Pages.TransactionDetailsPage />} />

        <Route path={"/"} element={<Pages.MainPage />} />
    </Routes>
}
